<script lang="ts">
  import { errorProvider } from '$lib/error-handling/errorProvider';

  import { closeModal } from '$lib/stores/modals.store';
  import type { ClientUserStore } from 'shared/src';
  import Button from '../Button.svelte';

  import Modal from '../Modal.svelte';
  import CreditCardForm from './CreditCardForm.svelte';

  export let clientUserStore: ClientUserStore;

  const modalId = 'change-payment-method';

  let state: 'editing' | 'saving' | 'errored' | 'done' = 'editing';

  let errorMessage: string = '';
  async function onSubmitPayment(token: string, country: string, postalCode: string) {
    state = 'saving';
    try {
      await clientUserStore.api.updateCard(token, country, postalCode);
      state = 'done';
    } catch (err: any) {
      errorMessage = err?.message || '';
      state = 'errored';
      if (err?.status >= 500) {
        errorProvider.error(err);
      }
    }
  }
  async function onLocationChange() {}
</script>

<Modal {modalId} class="max-w-lg">
  <div slot="title" class="text-gray font-thin text-lg pb-3 border-b border-gray-100">
    Change Payment Method
  </div>
  <div class="pt-2 px-4">
    {#if state !== 'done'}
      <CreditCardForm {onSubmitPayment} {onLocationChange} buttonLabel="Update Payment Info">
        <div slot="error">
          {#if state === 'errored'}
            {errorMessage || 'There was an error changing the payment method. Please try again.'}
          {/if}
        </div>
      </CreditCardForm>
    {:else}
      <p>Payment method succesfully changed.</p>
      <Button on:click={() => closeModal()}>Close</Button>
    {/if}
  </div>
</Modal>
