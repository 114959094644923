<script lang="ts">
  import { autorun } from 'mobx';
  import FlyOutMenu from '$lib/components/FlyOutMenu.svelte';
  import type { ClientUserStore, SectionStore } from 'shared/src';
  import { onDestroy } from 'svelte';
  import MaterialIcon from '$lib/MaterialIcon.svelte';
  import { mdiChairSchool, mdiEyeOutline, mdiSchoolOutline } from '@mdi/js';
  import { sortByFactory } from 'shared/src/utils/sortByFactory';
  export let clientUserStore: ClientUserStore;

  let menuVisible = false;

  let props: {
    defaultSection: SectionStore | undefined;
    taughtOrEnrolledSections: SectionStore[];
    currentSectionId: string;
    currentSection: SectionStore;
    isTeacher: boolean;
  };

  const destroyer = autorun(() => {
    const sections = clientUserStore.allSections;
    props = {
      defaultSection: sections.find((s) => s.isDefaultSection),
      taughtOrEnrolledSections: sections
        .filter((s) => !s.isDefaultSection)
        .sort(sortByFactory('sectionName')),
      currentSectionId: clientUserStore.currentSection.id,
      currentSection: clientUserStore.currentSection,
      isTeacher: clientUserStore.roles.includes('teacher'),
    };
  });

  onDestroy(destroyer);

  function changeSection(id?: string) {
    if (id) {
      clientUserStore.changeSection(id);
      menuVisible = false;
    }
  }

  const toggleMenu = (evt: Event) => {
    menuVisible = !menuVisible;
    evt.stopPropagation();
  };
</script>

<div class="relative">
  <button
    class="border border-gray-100 rounded-sm mr-2"
    class:bg-orange-500={menuVisible}
    on:click={toggleMenu}
  >
    <span class="sr-only">Toggle Current Class Section View</span>
    {#if props.isTeacher}
      {#if props.currentSection.isDefaultSection}
        <MaterialIcon path={mdiSchoolOutline} label="Professor's Cap" class="h-8 px-1" />
        <!-- <i class="icon teacher-icon" title="Viewing as teacher" /> -->
      {:else}
        <MaterialIcon path={mdiChairSchool} label="Student Desk" class="h-8 px-1" />

        <!-- <i
          class="icon student-icon active"
          title="Viewing as student in {props.currentSection.sectionName}"
        /> -->
      {/if}
    {:else}
      <MaterialIcon path={mdiEyeOutline} label="Eyeball Icon" class="h-8 px-0.5" />
    {/if}
  </button>
  <FlyOutMenu bind:menuVisible>
    <span slot="title" class="text-blue-800 font-medium">Select class view:</span>
    <div class="text-sm class-list-scroll mb-2">
      <ul>
        {#if props.defaultSection}
          <li
            class="selected  my-1 border-y border-y-gray-100"
            class:selected={props.defaultSection &&
              props.defaultSection.id === clientUserStore.currentSection.id}
          >
            <button on:click={() => changeSection(props.defaultSection && props.defaultSection.id)}
              >View as Teacher (all content)</button
            >
          </li>
        {/if}
        {#each props.taughtOrEnrolledSections as section}
          <li class:selected={section.id === clientUserStore.currentSection.id}>
            <button on:click={() => changeSection(section.id)}>{section.sectionName}</button>
          </li>
        {/each}
      </ul>
    </div>
  </FlyOutMenu>
</div>

<style lang="postcss">
  .class-list-scroll {
    max-height: calc(100vh - 120px);
    overflow: auto;
  }

  li {
    @apply block text-blue-800;
    button {
      @apply font-thin w-full text-left p-2;
    }
    &.selected {
      @apply bg-blue-100;
      button {
        @apply font-medium text-blue-800;
      }
    }
  }

  .icon {
    &:before {
      content: '';
      display: block;
      width: 32px; /* 77 * 5/8 */
      height: 32px; /* 160 * 5/8 */
      background-repeat: no-repeat;
    }

    &.teacher-icon {
      &:before {
        background-image: url('/img/app/teacher-view-sprites.png');
        background-position: 5px 5px;
        background-size: 21px 50px; /* 80 * 3/8, 190 * 3/8 */
      }
    }

    &.student-icon {
      &:before {
        background-image: url('/img/app/teacher-view-sprites.png');
        background-position: 4px -38px;
        background-size: 30px 72px; /* 80 * 3/8, 190 * 3/8 */
      }
    }

    &.active {
      &:before {
        background-color: var(--button-color, @button-color);
      }
    }
  }
</style>
