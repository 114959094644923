export const subscriptionPlans = [
  {
    amount: 599,
    monthlyAmount: 599,
    name: 'monthly',
    interval: 'month',
    title: 'Monthly Subscription',
  },
  {
    amount: 3999,
    monthlyAmount: 333,
    name: 'yearly',
    interval: 'year',
    title: 'Annual Subscription',
  },
] as const;
