<script lang="ts">
  // your script goes here

  import Modal from '$lib/components/Modal.svelte';
  import { closeModal } from '$lib/stores/modals.store';
  import type { ClientUserStore } from 'shared/src';
  import { page } from '$app/stores';
  import { errorProvider } from '$lib/error-handling/errorProvider';
  import LoadingSpinner from '$lib/components/LoadingSpinner.svelte';
  import LoginForm from '$lib/app/authentication/LoginForm.svelte';
  import Button from '$lib/components/Button.svelte';
  export let clientUserStore: ClientUserStore;
  const modalId = 'sessionExpiredMustLoginModal';

  let timeout: number | NodeJS.Timeout | undefined;
  let anonymousUserId: string | undefined;
  let backoffMS = 100;
  let errorReported = false;
  let state: 'setup' | 'waiting' | 'loaded' | 'errored' = 'setup';

  function cleanup() {
    if (timeout !== undefined) {
      clearTimeout(timeout as NodeJS.Timeout);
    }
    timeout = undefined;
    anonymousUserId = undefined;
    backoffMS = 100;
    errorReported = false;
    state = 'waiting';
  }

  function loginComplete() {
    closeModal();
  }

  function onOpen() {
    getAnonymousUserId();
  }

  function getAnonymousUserId() {
    state = 'waiting';
    clientUserStore.api
      .isLoggedInAsCurrentUser()
      .then(({ isLoggedInAsCurrentUser, userId, anonymous }) => {
        if (isLoggedInAsCurrentUser && !anonymous) {
          // this should never happen
          closeModal();
        }

        anonymousUserId = anonymous ? userId : undefined;
        state = 'loaded';
      })
      .catch((err) => {
        console.error(err);
        state = 'errored';
        backoffMS *= 2;
        timeout = setTimeout(getAnonymousUserId, backoffMS);
        if (!errorReported) {
          errorProvider.error(err);
          errorReported = true;
        }
      });
  }
</script>

<Modal {modalId} class="max-w-2xl" on:open={onOpen} on:close={cleanup} dismissable={false}>
  <div slot="title" class="text-red card-padding">
    You have been logged out. Sign in to save your progress.
  </div>

  {#if state === 'setup'}
    <Button class="mx-auto" on:click={getAnonymousUserId}>Click to log in again.</Button>
  {:else if state === 'waiting'}
    <LoadingSpinner /> Loading user info...
  {:else if state === 'errored'}
    Error loading user info... we'll try again in {backoffMS / 100} seconds.
  {:else if state === 'loaded'}
    <LoginForm
      {clientUserStore}
      postSocialLoginRedirectUrl={$page.url.pathname}
      on:loginComplete={loginComplete}
      hideRegistrationOption
      parameters={{ anonymousUserId }}
    />
  {/if}
</Modal>
