<script lang="ts">
  import { autorun } from 'mobx';
  import { openModal } from '$lib/stores/modals.store';
  import { ClientUserStore } from 'shared/src';
  import { onDestroy } from 'svelte';
  import { Popover, PopoverButton, PopoverPanel, Transition } from '@rgossiaux/svelte-headlessui';
  import { twMerge } from 'tailwind-merge';
  import AvatarImage from './AvatarImage.svelte';
  import LogoutButton from '../authentication/LogoutButton.svelte';
  import type { ServerUserStore } from '$lib/stores/serverUserStore';

  export let userStore: ClientUserStore | ServerUserStore;
  let clazz: string = '';
  export { clazz as class };
  const defaultAvatarUrl = '/img/app/avatar-1.jpg';
  let props = {
    isAnonymous: userStore.type === 'anonymous',
    userAvatarUrl: userStore.avatarUrl,
    defaultAvatarUrl,
    initials: (userStore.firstName?.[0] || '') + (userStore.lastName?.[0] || ''),
    firstInitial: userStore.firstName?.[0] || '' || userStore.lastName?.[0] || '',
    avatarLoadError: false,
  };
  const destroyer = autorun(() => {
    props = {
      isAnonymous: userStore.type === 'anonymous',
      userAvatarUrl: userStore.avatarUrl,
      defaultAvatarUrl,
      initials: (userStore.firstName?.[0] || '') + (userStore.lastName?.[0] || ''),
      firstInitial: userStore.firstName?.[0] || '' || userStore.lastName?.[0] || '',
      avatarLoadError: false,
    };
  });

  /** Sometimes an avatar is lost -- i.e., Facebook url changes. So: fallback! */

  onDestroy(destroyer);
</script>

<div class={twMerge(`flex justify-center mx-2 w-16 ${clazz}`)}>
  {#if !props.isAnonymous}
    <Popover class="h-12">
      <PopoverButton class="my-auto" type="div" role="button">
        <AvatarImage class="h-12 w-12" src={props.userAvatarUrl} initial={props.firstInitial} />
      </PopoverButton>
      <Transition
        enter="relative z-10 transform transition ease-in duration-150"
        enterFrom="relative z-10 opacity-0 rotate-x-90"
        enterTo="relative z-10 opacity-100 rotate-x-0"
        leave="relative z-10 opacity-100 ease-out duration-150"
        leaveFrom="relative z-10 opacity-100 rotate-x-0"
        leaveTo="relative z-10 opacity-0 rotate-x-90"
        entered="relative z-10 opacity-100 rotate-x-0"
      >
        <PopoverPanel
          class="
        absolute
        right-0
        text-black
        shadow-lg
        border
        border-gray-100
        bg-white
        "
        >
          <div class="text-blue-800 py-2">
            <a
              class="block font-normal text-blue px-4 py-2 w-full whitespace-nowrap hover:bg-gray-50"
              href="/app/dashboard/edit-profile">Edit Account</a
            >
            {#if userStore instanceof ClientUserStore}
              <LogoutButton
                class="block font-normal text-blue px-4 py-2 w-full whitespace-nowrap hover:bg-gray-50"
                clientUserStore={userStore}
              />
            {/if}
          </div>
        </PopoverPanel>
      </Transition>
    </Popover>
  {:else}
    <div class="flex flex-col">
      <button class="bg-transparent py-0.5" on:click={() => openModal('login')}>Sign In</button>
      <button class="bg-transparent py-0.5" on:click={() => openModal('register')}>Register</button>
    </div>
  {/if}
</div>
