<script lang="ts">
  import { errorProvider } from '$lib/error-handling/errorProvider';

  import type { Subscription } from 'shared/definitions/subscription-definitions';

  import type { ClientUserStore } from 'shared/src';
  import { createEventDispatcher } from 'svelte';
  import LoadingSpinner from '../LoadingSpinner.svelte';

  import CreditCardForm from './CreditCardForm.svelte';
  import { subscriptionPlans } from './subscriptionPlans';

  export let clientUserStore: ClientUserStore;

  let creditCardForm: CreditCardForm;

  export function clear() {
    if (creditCardForm) creditCardForm.clear();
  }

  interface $$Events {
    complete: CustomEvent<Subscription>;
  }

  let selectedPlan: typeof subscriptionPlans[number] = subscriptionPlans[0];

  $: subtotal = selectedPlan.amount;
  let tax = 0;
  $: total = subtotal + tax;

  const dispatch = createEventDispatcher<{ complete: Subscription }>();

  async function onSubmitPayment(token: string, country: string, postalCode: string) {
    const subscription = await clientUserStore.api.subscribe(
      selectedPlan.name,
      country,
      postalCode,
      token
    );
    dispatch('complete', subscription);
  }

  async function onLocationChange(country: string, postalCode: string) {
    await calculateTax(country, postalCode);
  }

  let taxCalculating = false;
  let taxCalculationError = '';
  async function calculateTax(country: string, postalCode: string) {
    taxCalculating = true;
    taxCalculationError = '';
    try {
      const invoice = await clientUserStore.api.previewSubscriptionInvoice({
        planId: selectedPlan.name,
        zip: postalCode,
        country,
      });
      tax = invoice.tax || 0;
    } catch (err: any) {
      taxCalculationError =
        typeof err === 'string' ? err : err?.message || 'Error calculating tax.';
      if (err?.status >= 500) {
        errorProvider.error(err);
      }
    }
    taxCalculating = false;
  }
</script>

<div class="flex space-x-2 m-2">
  {#each subscriptionPlans as plan}
    <label class="plan-label" class:selected={selectedPlan === plan}
      ><input type="radio" bind:group={selectedPlan} name="subscription-plan" value={plan} />
      <p>{plan.title}</p>
      <p class="text-xs">${(plan.monthlyAmount / 100).toFixed(2)} / Month</p>
      <p class="text-xs">Renews Automatically</p>
    </label>
  {/each}
</div>
<CreditCardForm
  bind:this={creditCardForm}
  {onLocationChange}
  {onSubmitPayment}
  disabled={taxCalculating}
  class="mx-2"
>
  <span slot="error">{taxCalculationError}</span>
  <div class="text-right mt-2">
    <p>Subtotal: ${(subtotal / 100).toFixed(2)}</p>
    <p>
      Tax:
      {#if taxCalculating}<LoadingSpinner size="1em" />{:else}${(tax / 100).toFixed(2)}{/if}
    </p>
    <p>Total: ${(total / 100).toFixed(2)}</p>
  </div>
</CreditCardForm>

<style lang="postcss">
  .plan-label {
    @apply block bg-gray-50 text-gray flex-grow text-center p-2 transition-colors;
    &.selected {
      @apply bg-orange text-white;
    }
    input[type='radio'] {
      opacity: 0;
      position: fixed;
      width: 0;
    }
  }
</style>
