<!---- 
  -- /app layout
  -- It sets up:
  --  * Navbar/header/etc
  --  * Modals that need to be globally accessible from all student pages
  --  * Alerts & Flashes
  --
  -- It is NOT used by /app/teach pages.
  --
  -- It inherits from /routes/__layout-base.svelte, which sets up analytics
  -- and error/exception tracking.
  ---->
<script context="module" lang="ts">
  import { getUserStore } from '$lib/stores/getUserStore';

  import type { Load } from '@sveltejs/kit';
  import type { ClientUserStore } from 'shared/src';

  export const load: Load = async ({ session, fetch, url }) => {
    const serverUserStore =
      !browser && session.user ? getServerUserStore({ userWithJoins: session.user }) : undefined;

    const clientUserStore = !serverUserStore
      ? await getUserStore({ userWithJoins: session.user, fetch })
      : undefined;

    if (clientUserStore) {
      // If the user has a required test landing page, let's start there:
      const landingPageTest = clientUserStore.landingPageTest;
      const testUrl = `/app/tests/${landingPageTest?.id}`;

      clientUserStore.hasBeenRedirectedToLandingPageTest =
        clientUserStore.hasBeenRedirectedToLandingPageTest || url.toString().includes(testUrl);

      if (
        !clientUserStore.hasBeenRedirectedToLandingPageTest &&
        landingPageTest &&
        !clientUserStore.roles.includes('teacher') &&
        !clientUserStore.roles.includes('superUser') &&
        !url.toString().includes(testUrl)
      ) {
        clientUserStore.hasBeenRedirectedToLandingPageTest = true;
        return {
          redirect: `/app/tests/${clientUserStore.landingPageTest.id}`,
          status: 302,
        };
      }

      if (typeof window !== 'undefined') {
        (window as any).store = clientUserStore;
      }
    }

    return {
      stuff: {
        clientUserStore,
        serverUserStore,
      },
      props: {
        clientUserStore,
        serverUserStore,
      },
      cache: {
        maxage: 0,
      },
    };
  };
</script>

<script lang="ts">
  import '../../app.postcss';
  import { autorun } from 'mobx';
  import '../../app.postcss';
  import LoginModal from '$lib/app/modals/authentication/LoginModal.svelte';
  import RegisterModal from '$lib/app/modals/authentication/RegisterModal.svelte';
  import ChangePaymentMethodModal from '$lib/components/payments/ChangePaymentMethodModal.svelte';
  import AppHeader from '$lib/app/nav/AppHeader.svelte';
  import SubscribeModal from '$lib/components/payments/SubscribeModal.svelte';
  import MustRegisterModal from '$lib/app/modals/MustRegisterModal.svelte';
  import { onDestroy } from 'svelte';
  import SupportModal from '$lib/app/modals/SupportModal.svelte';

  import { afterNavigate, beforeNavigate } from '$app/navigation';
  import { openModal } from '$lib/stores/modals.store';
  import MustSubscribeModal from '$lib/app/modals/MustSubscribeModal.svelte';
  import { addBreadcrumb } from '$lib/stores/breadcrumbs.store';
  import Alerts from '$lib/components/Alerts.svelte';
  import { observeApiErrors } from '$lib/app/apiErrors/apiErrorsStore';
  import SessionExpiredMustLoginModal from '$lib/app/modals/authentication/SessionExpiredMustLoginModal.svelte';
  import Flashes from '$lib/app/flashes/Flashes.svelte';
  import PasswordResetModal from '$lib/app/modals/authentication/PasswordResetModal.svelte';
  import { page } from '$app/stores';
  import { replaceStateWithQuery } from '$lib/replaceStateWithQuery';
  import { browser } from '$app/env';
  import { getServerUserStore, ServerUserStore } from '$lib/stores/serverUserStore';

  export let clientUserStore: ClientUserStore | undefined;
  export let serverUserStore: ServerUserStore | undefined;
  let userStore = serverUserStore || clientUserStore!;

  // If we don't have permission to view this page, through up the paywall before navigating to it.
  beforeNavigate(({ from, to, cancel }) => {
    if (!clientUserStore) return;
    // Do we have pages remaining? Proceed as normal.
    if (clientUserStore.hasPagesRemainingToday) return;
    // Is this a paywallable page:
    const isPaywallable = !![
      /\/app\/lessons\/([^\/]+)\/([^\/]+)/,
      /\/app\/skills\/practice/,
    ].find((re) => re.exec(to?.toString() || ''));
    if (isPaywallable) {
      cancel();
      if (clientUserStore.type === 'anonymous') {
        openModal('must-register');
      } else {
        openModal('must-subscribe');
      }
    }
  });

  // Update the breadcrumbs/back button component
  afterNavigate(({ to }) => {
    if (!to) return;
    addBreadcrumb(to, typeof document !== 'undefined' ? document.title : 'uTheory');

    // If there's a modal request in the url, open it, and clear the modalId
    const modalId = $page.url.searchParams.get('modal');
    if (modalId && browser) {
      openModal(modalId);
      const params = Object.fromEntries($page.url.searchParams);
      replaceStateWithQuery({
        ...params,
        modal: null,
      });
    }
  });

  let props: {
    theme: ClientUserStore['theme'];
    gradeToDate: number;
    anonymousUserId: string | undefined;
  };

  const apiErrorDestroyer = clientUserStore ? observeApiErrors(clientUserStore) : () => {};

  const destroyer = autorun(() => {
    props = {
      theme: clientUserStore?.theme || serverUserStore?.theme || 'normal',
      // This is a kluge to keep this observed and prevent
      // recalculation when navigating back to dashboard
      gradeToDate: clientUserStore?.gradeToDate || 0,
      anonymousUserId:
        !clientUserStore?.demoUser && clientUserStore?.type === 'anonymous'
          ? clientUserStore.id
          : undefined,
    };
  });

  onDestroy(() => {
    destroyer();
    apiErrorDestroyer();
  });
</script>

<div class="app app-root {props.theme} bg-blue-75 min-h-screen font-helvetica pb-4">
  {#if clientUserStore}
    <SessionExpiredMustLoginModal {clientUserStore} />
    <ChangePaymentMethodModal {clientUserStore} />
    <RegisterModal {clientUserStore} parameters={{ anonymousUserId: props.anonymousUserId }} />
    <SubscribeModal {clientUserStore} />
    <Flashes {clientUserStore} />
  {/if}
  <AppHeader {userStore} />
  <PasswordResetModal />
  <LoginModal {clientUserStore} parameters={{ anonymousUserId: props.anonymousUserId }} />
  <MustSubscribeModal />
  <MustRegisterModal />
  <SupportModal />
  <div class="text-gray {props.theme}">
    <Alerts class="top-0 bottom-auto" />
    <main id="maincontent">
      <slot />
    </main>
  </div>
</div>

<style lang="postcss">
  :global(.app) {
    :global(.a) {
      @apply text-blue no-underline hover:underline;
    }
  }
</style>
