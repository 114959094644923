<script lang="ts">
  import { afterNavigate } from '$app/navigation';
  import MaterialIcon from '$lib/MaterialIcon.svelte';
  import { openModal } from '$lib/stores/modals.store';
  import type { ServerUserStore } from '$lib/stores/serverUserStore';
  import { mdiMenu } from '@mdi/js';
  import { Popover, PopoverButton, PopoverPanel, Transition } from '@rgossiaux/svelte-headlessui';
  import { autorun } from 'mobx';

  import type { ClientUserStore } from 'shared/src';
  import { onDestroy, onMount } from 'svelte';
  import Avatar from '../avatar/Avatar.svelte';

  let clazz: string = '';
  export { clazz as class };
  export let style: string = '';
  export let path: string = '';
  export let isTeacher: boolean;
  export let tests: ClientUserStore['allTests'];
  export let userStore: ClientUserStore | ServerUserStore;
  export let containerElement: HTMLElement;

  let props = {
    showSubscribeLink: !userStore.isPaid && userStore.type !== 'anonymous',
  };

  const destroyer = autorun(() => {
    props = {
      showSubscribeLink: !userStore.isPaid && userStore.type !== 'anonymous',
    };
  });

  $: {
    if (isTeacher || true) updateHighlightPosition();
  }

  onDestroy(destroyer);

  let bigScreenUlEl: HTMLUListElement;
  afterNavigate(({ to }) => updateHighlightPosition(to.toString()));
  updateHighlightPosition();

  const resizeListener = () => {
    if (bigScreenNav && hamburgerNav && containerElement) {
      bigScreenNav.classList.remove('hidden');
      hamburgerNav.classList.add('hidden');
      if (containerElement.scrollWidth - 2 > containerElement.clientWidth) {
        bigScreenNav.classList.add('hidden');
        hamburgerNav.classList.remove('hidden');
      }
    }
    updateHighlightPosition();
  };

  onMount(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', resizeListener);
    }
    // This is hacky -- if Roboto hasn't loaded we get a FOUT and slight
    // size change, so, on first load we do a few resizes just in case
    setTimeout(resizeListener, 500);
    setTimeout(resizeListener, 1000);
    setTimeout(resizeListener, 2000);
  });

  onDestroy(() => {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', resizeListener);
    }
  });

  // Opacity is only used until the first mounted render -- this prevents
  // any flying green entrances, and instead gives a nice fade-in
  let pathHighlightCoords = {
    top: -1500,
    left: 1500,
    width: 0,
    height: 0,
    opacity: 0,
  };

  function updateHighlightPosition(urlString: string = path) {
    if (!bigScreenUlEl || !urlString) return;
    const selectedEl: HTMLLIElement | null = bigScreenUlEl.querySelector('.selected');
    pathHighlightCoords = {
      top: selectedEl ? selectedEl.offsetTop : -1500,
      left: selectedEl ? selectedEl.offsetLeft : 1500,
      width: selectedEl ? selectedEl.offsetWidth : 0,
      height: selectedEl ? selectedEl.offsetHeight : 0,
      opacity: 1,
    };
  }

  $: testLinkText = tests.length === 1 ? tests[0]?.definition.tabTitle : 'Tests';
  $: testLink = tests.length === 1 ? `/app/tests/${tests[0]!.id}` : `/app/tests`;

  let bigScreenNav: HTMLElement;
  let hamburgerNav: HTMLElement;
</script>

<nav
  bind:this={bigScreenNav}
  class="flex-grow text-white bg-cyan-600 high-contrast:bg-blue-800 {clazz}"
  {style}
>
  <ul bind:this={bigScreenUlEl} class="flex items-center flex-row">
    <li
      class="bg-green-400 high-contrast:bg-green-700 absolute"
      style="
      top: {pathHighlightCoords.top}px;
      left: {pathHighlightCoords.left}px;
      width: {pathHighlightCoords.width}px;
      height: {pathHighlightCoords.height}px;
      opacity: {pathHighlightCoords.opacity};
      transition: left 0.3s ease-out, width 0.3s ease-out, opacity 0.3s ease-in 0.3s;
    "
    />
    <li>
      <a
        sveltekit:prefetch
        class={path.includes('/dashboard') ? 'selected' : ''}
        href="/app/dashboard">Dashboard</a
      >
    </li>
    <li>
      <a
        sveltekit:prefetch
        class={path.includes('/lessons') || path.includes('/checkpoints') ? 'selected' : ''}
        href="/app/lessons">Lessons</a
      >
    </li>
    <li>
      <a sveltekit:prefetch class={path.includes('/skills') ? 'selected' : ''} href="/app/skills"
        >Skills</a
      >
    </li>
    {#if tests.length}
      <li>
        <a
          sveltekit:prefetch
          class="{path.includes('/tests') ? 'selected' : ''} whitespace-nowrap"
          href={testLink}>{testLinkText}</a
        >
      </li>
    {/if}
    {#if isTeacher}
      <li>
        <a
          sveltekit:prefetch
          class={path.includes('/teach') ? 'selected' : ''}
          href="/app/teach/classes">Teach</a
        >
      </li>
    {/if}
    {#if props.showSubscribeLink}
      <li class="flex-grow justify-end">
        <button
          class="text-sm font-normal"
          on:click={() => {
            openModal('subscribe-modal');
          }}>Free account</button
        >
      </li>
    {/if}
    <li class="justify-end" class:flex-grow={!props.showSubscribeLink}>
      <Avatar {userStore} />
    </li>
  </ul>
</nav>

<nav bind:this={hamburgerNav} class="hidden flex-grow z-50">
  <div
    class="flex small-screen-nav-menu flex-grow justify-end text-white bg-blue high-contrast:bg-blue-800 z-50 {clazz}"
    {style}
  >
    {#if props.showSubscribeLink}
      <div class="flex flex-grow align-middle justify-end">
        <button
          class="text-sm font-normal mr-2"
          on:click={() => {
            openModal('subscribe-modal');
          }}>Free account</button
        >
      </div>
    {/if}
    <Popover let:open>
      <PopoverButton
        as="div"
        type="div"
        role="button"
        class="flex-grow text-right text-white text-2xl"
      >
        <MaterialIcon
          path={mdiMenu}
          class="h-12 w-12 text-2xl text-white transition-colors {open ? 'bg-orange' : ''}"
          label="Toggle nav menu open/close"
          tabindex={0}
        />
      </PopoverButton>
      <Transition
        enter="transform transition ease-in duration-150"
        enterFrom="opacity-0 rotate-x-90"
        enterTo="opacity-100 rotate-x-0"
        leave="opacity-100 ease-out duration-300"
        leaveFrom="opacity-100 rotate-x-0"
        leaveTo="opacity-0 rotate-x-90"
        entered="opacity-100 rotate-x-0"
      >
        <PopoverPanel let:close>
          <ul
            class="mx-0 w-screen xs:w-80 absolute shadow-lg border-gray-100 border right-0 bg-white text-gray flex-col items-center"
          >
            <li>
              <a
                sveltekit:prefetch
                class={path.includes('/dashboard') ? 'selected' : ''}
                on:click={() => close(null)}
                href="/app/dashboard">Dashboard</a
              >
            </li>
            <li>
              <a
                sveltekit:prefetch
                class={path.includes('/lessons') || path.includes('/checkpoints') ? 'selected' : ''}
                on:click={() => close(null)}
                href="/app/lessons">Lessons</a
              >
            </li>
            <li>
              <a
                sveltekit:prefetch
                class={path.includes('/skills') ? 'selected' : ''}
                on:click={() => close(null)}
                href="/app/skills">Skills</a
              >
            </li>
            {#if tests.length}
              <li>
                <a
                  sveltekit:prefetch
                  class={path.includes('/tests') ? 'selected' : ''}
                  on:click={() => close(null)}
                  href={testLink}>{testLinkText}</a
                >
              </li>
            {/if}
            {#if isTeacher}
              <li>
                <a
                  sveltekit:prefetch
                  class={path.includes('/teach') ? 'selected' : ''}
                  on:click={() => close(null)}
                  href="/app/teach/classes">Teach</a
                >
              </li>
            {/if}
            <li class="justify-end" class:flex-grow={!props.showSubscribeLink}>
              <Avatar {userStore} />
            </li>
          </ul>
        </PopoverPanel>
      </Transition>
    </Popover>
  </div>
</nav>

<style lang="postcss">
  ul > li {
    @apply text-lg font-semibold h-14 flex items-center;
    /* .selected {
      @apply bg-green-400;
    } */
  }

  .small-screen-nav-menu {
    li {
      a {
        @apply w-full text-gray font-normal pl-8 text-right;
      }
      .selected {
        transition: background-color 0.1s ease-in;
        @apply text-white bg-green-400;
      }
    }
  }
  :global(.high-contrast) {
    li {
      .selected {
        @apply bg-green-700;
      }
    }
  }

  a {
    transition: background-color 0.25s;
    @apply block py-4 px-4 justify-center h-full z-10;
  }
</style>
