<script lang="ts">
  import FlyOutMenu from '$lib/components/FlyOutMenu.svelte';
  import type { ClientUserStore } from 'shared/src';
  import MaterialIcon from '$lib/MaterialIcon.svelte';
  import { mdiPlusThick } from '@mdi/js';
  import TextField from '$lib/components/TextField.svelte';
  import Button from '$lib/components/Button.svelte';
  export let clientUserStore: ClientUserStore;

  let menuVisible = false;
  let classCode: string = '';

  let state: 'input' | 'processing' | 'complete' | 'errored' = 'input';

  let error: string | null = null;
  async function joinClass() {
    if (!classCode) {
      state = 'errored';
      error = 'Enter a class code first';
      return;
    }
    try {
      state = 'processing';
      await clientUserStore.api.joinSectionByInviteLink(classCode);
      state = 'complete';
    } catch (err: any) {
      state = 'errored';
      if (err.message) {
        error = err.message;
      }
      error = 'Error joining the class. Maybe the class code is wrong?';
    }
  }

  $: if (menuVisible === false) {
    state = 'input';
    classCode = '';
    error = '';
  }

  const toggleMenu = (evt: Event) => {
    menuVisible = !menuVisible;
    evt.stopPropagation();
  };
</script>

<div class="relative">
  <button
    class="border border-gray-100 rounded-sm mr-2"
    class:bg-orange-500={menuVisible}
    on:click={toggleMenu}
  >
    <span class="sr-only">Join a Class</span>
    <MaterialIcon path={mdiPlusThick} label="Join Class Button" class="h-8 px-0.5" />
  </button>
  <FlyOutMenu bind:menuVisible>
    <span slot="title" class="text-gray text-xl font-medium p-4">Join a Class</span>
    <div class="text-sm w-64 px-4 mt-4 text-gray mx-auto mb-4">
      {#if state !== 'complete'}
        <div>
          Ask your teacher for a class code, and then enter it here.<br />
          <TextField
            class="mt-2 w-full"
            width={8}
            placeholder="Class code"
            bind:value={classCode}
            on:input={() => {
              state = 'input';
              error = null;
            }}
            customValidityMessage={error || undefined}
            localValidationError={!!error}
          />
        </div>
        <div class="flex justify-between mt-2">
          <Button color="red" on:click={() => (menuVisible = false)}>Cancel</Button>
          <Button
            color="green"
            on:click={joinClass}
            disabled={!classCode || state === 'errored' || state === 'processing'}
            >Join Class</Button
          >
        </div>
      {:else}
        <div>You've been succesfully added to the class!</div>
        <div class="text-center">
          <Button
            color="green"
            on:click={() => {
              menuVisible = false;
            }}>Close</Button
          >
        </div>
      {/if}
    </div>
  </FlyOutMenu>
</div>
