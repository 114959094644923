<script lang="ts">
  import Button from '$lib/components/Button.svelte';
  import Hr from '$lib/components/Hr.svelte';
  import Modal from '$lib/components/Modal.svelte';
  import { closeModal } from '$lib/stores/modals.store';

  const modalId = 'support-modal';
</script>

<Modal {modalId} class="max-w-xl">
  <div slot="title">Support</div>
  <Hr class="my-4" />
  <div class="m-4">
    <p>Have a question? Found a bug? Or maybe you just want to give us some feedback!</p>
    <p>Whatever it is, we're eager to hear from you. You can reach us at:</p>
    <a class="a" href="mailto:support@utheory.com">support@utheory.com</a> or
    <a class="a" href="tel:+18668380556">(866) 838-0556</a>.
    <div class="text-right mt-4">
      <Button on:click={() => closeModal()}>Close</Button>
    </div>
  </div>
</Modal>

<style lang="postcss">
  p {
    @apply mb-4;
  }
</style>
