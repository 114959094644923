<script lang="ts">
  import { onDestroy, onMount } from 'svelte';

  import { slide } from 'svelte/transition';
  export let menuVisible: boolean;
  let klazz = 'fixed';
  export { klazz as class };

  // Listen for clicks/touches outside the menu:
  export const hideMenu = (evt: Event) => {
    if (!menuVisible) return;
    menuVisible = false;
    evt.stopPropagation();
  };

  export const toggleMenu = (evt: Event) => {
    menuVisible = !menuVisible;
    evt.stopPropagation();
  };

  const keypressEventListener = (evt: KeyboardEvent) => {
    if (evt.key === 'Escape') {
      if (menuVisible) {
        hideMenu(evt);
        evt.preventDefault();
      }
    }
  };
  onMount(() => {
    if (typeof window === 'undefined') return;
    window.addEventListener('click', hideMenu);
    window.addEventListener('keydown', keypressEventListener);
  });
  onDestroy(() => {
    if (typeof window === 'undefined') return;
    window.removeEventListener('click', hideMenu);
    window.removeEventListener('keydown', keypressEventListener);
  });
</script>

{#if menuVisible}
  <div
    on:click={(evt) => evt.stopPropagation()}
    class="
    w-full
    h-full
    left-0
    mt-2
    overflow-y-scroll
    xs:h-auto
    xs:w-auto
    xs:left-auto
    xs:rounded-b-md
    xs:mt-0
    border
    border-gray-100
    shadow-lg
    bg-white
    z-20
    py-2
    text-left
    {klazz}"
  >
    <div transition:slide|local>
      <div class="flex">
        <div class="flex-grow text-gray-800 px-2 pb-2">
          <slot name="title" />
        </div>
        <div class="mr-2">
          <button on:click={hideMenu}>
            <img
              class="h-4"
              src="/teach/t-landing/icon-off-canvas-menu-close.svg"
              alt="Close Menu"
            />
            <span class="sr-only">Close Menu</span>
          </button>
        </div>
      </div>
      <div class="text-gray-light text-base md:text-lg text-left">
        <slot />
      </div>
    </div>
  </div>
{/if}

<style lang="postcss">
  li {
    @apply block py-3;
  }
</style>
