<script lang="ts">
  import Button from '$lib/components/Button.svelte';
  import Hr from '$lib/components/Hr.svelte';
  import Logo from '$lib/components/Logo.svelte';

  import Modal from '$lib/components/Modal.svelte';
  import { openModal } from '$lib/stores/modals.store';
  const modalId = 'must-register';
</script>

<Modal {modalId} class="max-w-2xl">
  <div slot="title" class="text-gray font-thin text-lg py-4 border-b border-gray-100">
    You're on a roll! Register or sign in to keep learning!
  </div>
  <div class="xs:flex p-8 mr-4">
    <div class="glex-grow text-center">
      <Logo class="text-cyan w-9/12 max-w-xs mx-auto" />
    </div>
    <div class="text-center xs:text-left pt-2 xs:pt-0 xs:ml-8 min-w-7/12">
      <p class="text-lg font-medium block w-fit">
        Sign up for a uTheory account to get five more free exercises a day.
        <Hr class="w-11/12 mx-auto my-3" />
      </p>
      <p class="text-sm">Music theory learning starts with you.</p>
      <Button class="px-8 mt-4" on:click={() => openModal('register')}>Sign Up</Button>
    </div>
  </div>
</Modal>
