<script lang="ts">
  import { autorun } from 'mobx';
  import AppNav from '$lib/app/nav/AppNav.svelte';
  import Logo from '$lib/components/Logo.svelte';
  import { page } from '$app/stores';
  import ClassSelect from '$lib/app/nav/ClassSelect.svelte';
  import JoinClassPopup from '$lib/app/nav/JoinClassPopup.svelte';
  import { ClientUserStore } from 'shared/src';
  import { onDestroy } from 'svelte';
  import type { ServerUserStore } from '$lib/stores/serverUserStore';
  export let userStore: ClientUserStore | ServerUserStore;

  let props = getProps();

  const destroyer = autorun(() => {
    props = getProps();

    if (typeof window !== 'undefined') {
      if (userStore.theme === 'high-contrast') {
        document.body.classList.add('high-contrast');
      } else {
        document.body.classList.remove('high-contrast');
      }
    }
  });

  function getProps() {
    return {
      theme: userStore.theme,
      isTeacher: userStore.roles.includes('teacher'),
      tests: 'allTests' in userStore ? userStore.allTests : [],
      sections: 'allSections' in userStore ? userStore.allSections : [],
      isAnonymous: userStore.type === 'anonymous',
      ipBasedAccess: 'ipBasedAccess' in userStore ? userStore.ipBasedAccess : null,
    };
  }

  onDestroy(destroyer);

  let headerEl: HTMLElement;
</script>

<header class="flex max-w-full overflow-visible" bind:this={headerEl}>
  <div
    class="overflow-y-visible {props.theme} text-white bg-blue-500 min-w-fit max-w-1/4 flex flex-grow items-center"
  >
    <div class="flex-grow">
      <a href="/app/dashboard">
        <Logo class="text-white bg-blue-500 w-28 mx-4" />
      </a>
    </div>
    <a href="#maincontent" class="sr-only">Skip to main</a>
    {#if userStore instanceof ClientUserStore}
      {#if props.isTeacher || props.sections.length > 1}
        <ClassSelect clientUserStore={userStore} />
      {/if}
      {#if !props.isAnonymous}
        <JoinClassPopup clientUserStore={userStore} />
      {/if}
    {/if}
  </div>
  <div class="flex flex-grow items-center h-full">
    <AppNav
      path={$page.url.toString()}
      isTeacher={props.isTeacher}
      tests={props.tests}
      {userStore}
      containerElement={headerEl}
    />
  </div>
</header>
{#if props.ipBasedAccess}
  {#if props.ipBasedAccess.emailMatchIPFailMessage}
    <div class="bg-yellow-100 text-yellow-900 text-center py-2">
      {props.ipBasedAccess.emailMatchIPFailMessage}
    </div>
  {/if}
  {#if props.ipBasedAccess.hasIPBasedAccess && props.ipBasedAccess.institution && props.isAnonymous}
    <div class="bg-yellow-100 text-yellow-900 text-center py-2">
      You are getting unlimited access through {props.ipBasedAccess.institution}, but your work is
      not being saved. Sign in or register to save your progress.
    </div>
  {/if}
{/if}
<div class="text-gray bg-white {props.theme}">
  <main id="maincontent">
    <slot />
  </main>
</div>
