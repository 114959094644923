<script lang="ts">
  import { closeModal } from '$lib/stores/modals.store';
  import type { ClientUserStore } from 'shared/src';

  import Modal from '../Modal.svelte';
  import SubscriptionForm from './SubscriptionForm.svelte';

  export let clientUserStore: ClientUserStore;

  const modalId = 'subscribe-modal';

  let subscriptionForm: SubscriptionForm;

  function clear() {
    if (subscriptionForm) subscriptionForm.clear();
  }
</script>

<Modal
  {modalId}
  class="max-w-lg"
  on:open={() => {
    if (clientUserStore.isSubscribed) {
      setTimeout(closeModal, 300);
    }
  }}
  on:close={() => setTimeout(clear, 300)}
>
  <div slot="title" class="text-gray font-thin text-lg pb-3 border-b border-gray-100">
    Subscribe for Unlimited Access
  </div>
  <div class="pt-2 px-4">
    <SubscriptionForm {clientUserStore} on:complete={closeModal} bind:this={subscriptionForm} />
  </div>
</Modal>
